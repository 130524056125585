<template lang="pug">
.success_container
  AppAlertCard(:title="$t('payment.success_title')" :subtitle="$t('payment.success_description')")
    //- .invoice__name {{ invoice.name }}
    //- AppRow.mb-4
    //-   AppDescription.description(:label="$t('invoices.invoice')" :description="invoice.id")
    //-   AppDescription.description(:label="$t('invoices.servicio_id')" :description="invoice.servicio_id[1]")
    //- AppRow.mb-4
    //-   AppDescription.description(:label="$t('invoices.invoice_date')" :description="dateFormatter(invoice.invoice_date)")
    //-   AppDescription.description(:label="$t('invoices.amount_total')" :description="numberFormatter(invoice.amount_residual)")
  AppCard.mt-4.text-center
    span.label
      | {{$t('payment.info')}}
</template>

<script>
import AppCard from "@/components/AppCard";
import AppAlertCard from "@/components/AppAlertCard";
import AppDescription from "@/components/AppDescription";
import { dateFormatter, numberFormatter } from "@/utils/formatters";
import AppRow from "@/components/AppRow";
import { onMounted, ref } from "@vue/runtime-core";
import { store } from "@/store";

export default {
  components: { AppAlertCard, AppDescription, AppRow, AppCard },
  setup() {
    const invoice = ref({});
    onMounted(() => {
      store.APP_LOADING = false;
      invoice.value = store.invoicePaid;
    });

    return { invoice, dateFormatter, numberFormatter };
  },
};
</script>

<style lang="scss" scoped>
.invoice__name {
  @apply text-xs;
  @apply text-bluegray-400;
  @apply mb-2;
  @apply font-medium;
  @apply text-center;
}

.success_container {
  @apply mx-auto;
  @screen lg {
    @apply w-3/4;
  }
  @screen xl {
    @apply w-1/2;
  }
}

.description {
  @apply mx-4;
  @screen md {
    @apply mx-28;
  }
}

.label {
  @apply text-sm;
  @apply font-medium;
  @apply mb-2;
  @apply inline-block;
  @apply tracking-wide;
}
</style>
